import 'ol/ol.css';
import { Map, View } from 'ol';
import Geolocation from 'ol/Geolocation';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import { Cluster, Vector as VectorSource } from 'ol/source.js';
import { Circle as CircleStyle, Fill, Stroke, Style, Text, Icon } from 'ol/style.js';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import { fromLonLat, transform } from 'ol/proj';
// for watercolor tiles
//import Stamen from 'ol/source/Stamen.js';
import OSM from 'ol/source/OSM';

export var map;
var geolocation;

var clusterSource;
var locationSource;
var styleCache = {};
var locationIconStyle = new Style({
    image: new Icon({
        anchor: [0.5, 0.1],
        scale: 1,
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        opacity: 1.0,
        src: 'https://klowo.org/src/marker/baseline_directions_run.png'
    })
});
var locationIconFeature;

var backdroDisabled;

var goToToLocation;

var selectClosestToilet = false;

//the currently selected item
export var selectedItem;

export function initMap() {
    backdroDisabled = false;
    console.log('init Map');

    locationSource = new VectorSource({ features: [] });
    var vectorlayer = new VectorLayer({
        source: locationSource
    });

    var centerOn = fromLonLat([8.6851, 50.8729]);
    map = new Map({
        target: 'map',
        layers: [
            new TileLayer({ source: new OSM() }),
            //new TileLayer({ source: new Stamen({ layer: 'watercolor' }) }),
            //new TileLayer({ source: new Stamen({ layer: 'terrain-labels' }) }),
            vectorlayer
        ],
        view: new View({ center: centerOn, zoom: 6 })
    });

    initGeolocation();

    map.on('click', function(evt) {
        map.forEachFeatureAtPixel(evt.pixel, function(f, l) {
            console.log(f);
            var feature = f.get('features');
            var size = feature.length;
            var view = map.getView();
            var zoom = view.getZoom();
            if (size === 1) {
                console.log(feature);
                onItemClicked(feature[0].values_.item);
                if (zoom < 15) {
                    view.animate(
                        {
                            center: fromLonLat([
                                parseFloat(feature[0].values_.item.long),
                                1 * parseFloat(feature[0].values_.item.lat) + 0.003
                            ])
                        },
                        { zoom: 15 }
                    );
                }
            } else if (size > 1) {
                if (zoom < 10) {
                    view.animate(
                        {
                            center: fromLonLat([
                                parseFloat(feature[0].values_.item.long),
                                1 * parseFloat(feature[0].values_.item.lat) + 0.003
                            ])
                        },
                        { zoom: 10 }
                    );
                }
            }
        });
        if (!map.hasFeatureAtPixel(evt.pixel)) {
            showMap();
        }
    });

    httpToilets();
}

function onItemClicked(item) {
    if (item) {
        let element = document.getElementById('item-overlay');
        element.classList.remove('removeToTop');
        let buttons = document.getElementById('button-wrapper');
        if (buttons) {
            buttons.classList.add('removeToLeft');
        }
        document.getElementById('item-name').innerHTML = item.item_name;
        document.getElementById('item-date').innerHTML = new Date(item.timestamp).toLocaleDateString();
        document.getElementById('item-provider').innerHTML = item.provider === 'user' ? 'Jane Doh' : item.provider;
        //var nav_url = 'https://maps.google.com/maps?daddr=' + item.lat + ',' + item.long + '';
        //document.getElementById('google-navigate').innerHTML =
        //'<a href="' + nav_url + '"><i class="material-icons">directions</i></a>';
        selectedItem = item;
        getRatings(item.item_id);
        getImages(item.item_id);
        getComments(item.item_id);
    }
}

function showMap() {
    console.log('show map (blocked: ' + backdroDisabled + ')');
    if (!backdroDisabled) {
        let startOverlay = document.getElementById('item-overlay');
        if (startOverlay) {
            startOverlay.classList.add('removeToTop');
        }
        let buttons = document.getElementById('button-wrapper');
        if (buttons) {
            buttons.classList.remove('removeToLeft');
        }
        document.getElementById('rating').innerHTML = '';
        document.getElementById('comments').innerHTML = '';
    }
}

export function blockBackdrop(value) {
    backdroDisabled = value;
}

export function isBlocked() {
    return backdroDisabled;
}

export function getRatings(item_id) {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://klowo.org/klowo_items.php', true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onreadystatechange = function() {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            var ratings = JSON.parse(this.response);
            if (ratings && ratings.length) {
                var sum = 0;
                for (var i = 0; i < ratings.length; i++) {
                    sum += parseInt(ratings[i].value);
                }
                var average = sum / ratings.length;
                console.log('Rating: ' + sum + ' / ' + ratings.length + ' = ' + average);
                setRating(Math.round(average * 10) / 10);
            }
        }
    };
    xhr.send('get=ratings&id=' + item_id);
}
export function getImages(item_id) {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://klowo.org/klowo_items.php', true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onreadystatechange = function() {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            if (this.response != null) {
                var images = JSON.parse(this.response);
                setImages(images);
            } else {
                setImages([{ name: 'klo_1' }]);
            }
        }
    };
    xhr.send('get=images&id=' + item_id);
}
function setImages(images) {
    console.log(images);
    var list = document.getElementById('item-images');
    while (list.childNodes.length > 2) {
        list.removeChild(list.childNodes[0]);
    }
    if (images) {
        for (var i = 0; i <= images.length - 1; i++) {
            var itemImage = document.createElement('div');
            itemImage.classList.add('item-image');
            var img = document.createElement('img');
            img.src = 'https://klowo.org/klowo_getimage.php?image_name=' + images[i].name;
            itemImage.appendChild(img);
            list.insertBefore(itemImage, list.firstChild);
        }
    }
}

export function getComments(item_id) {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://klowo.org/klowo_items.php', true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onreadystatechange = function() {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            seComments(JSON.parse(this.response));
        }
    };
    xhr.send('get=comments&id=' + item_id);
}

function createStarDiv(number) {
    let outstring = '';
    let stars = number;
    let max = 5;
    if (number > max) {
        stars = max;
    }
    for (let i = 0; i < stars; i++) {
        outstring += '<i class="material-icons">star</i>';
    }
    for (let i = 0; i < max - stars; i++) {
        outstring += '<i class="material-icons">star_border</i>';
    }
    return outstring;
}

function setRating(value) {
    console.log('set rating: ' + value);
    let element = document.getElementById('rating');
    element.innerHTML = '';
    if (value) {
        element.innerHTML = '<i class="material-icons">star</i>' + value;
    }
}

function seComments(c) {
    console.log('comments:');
    console.log(c);
    let div = document.getElementById('comments');
    div.innerHTML = '';
    if (c && c.length > 0) {
        for (var i = c.length - 1; i >= 0; i--) {
            console.log(c[i].comment + ' id' + i);
            var commentDiv = document.createElement('div');
            commentDiv.classList.add('comment');
            let date = new Date(c[i].timestamp);
            commentDiv.innerHTML =
                '<i class="material-icons" >sentiment_very_satisfied</i> ' +
                c[i].comment +
                ' <span class="comment-time">' +
                date.toLocaleDateString() +
                '</span>';
            div.appendChild(commentDiv);
        }
    }
}

export function httpToilets(coordinates) {
    var url = 'https://klowo.org/klowo_items.php';
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function() {
        if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
            var resObj = JSON.parse(this.responseText);
            console.log(resObj);
            setitems(resObj);
            if (coordinates) {
                selectClosestToiletAt(center);
            }
        }
    };
    xmlHttp.open('GET', url, true); // true for asynchronous
    xmlHttp.send(null);
}

function setitems(items) {
    console.log('setItens');
    var features = [];
    for (let i = 0; i < items.length; i++) {
        features.push(
            new Feature({
                geometry: new Point(fromLonLat([parseFloat(items[i].long), parseFloat(items[i].lat)])),
                id: items[i].id,
                name: 'Klo',
                item: items[i]
            })
        );
    }
    console.log('+ fetures added');

    clusterSource = new Cluster({
        distance: 30,
        source: new VectorSource({ features: features })
    });

    var vectorlayer = new VectorLayer({
        source: clusterSource,
        style: function(feature) {
            var size = feature.get('features').length;
            var style = styleCache[size];
            if (!style > 0) {
                style = new Style({
                    image: new Icon({
                        anchor: [0.5, 1],
                        scale: 1,
                        opacity: 1,
                        src:
                            size > 2
                                ? 'https://klowo.org/src/marker/marker_cluster_1.png'
                                : 'https://klowo.org/src/marker/marker_1.png'
                    }),
                    text: new Text({
                        text: size === 2 ? '2x' : '',
                        offsetY: -25,
                        offsetX: 1,
                        fill: new Fill({
                            color: '#ff9bff'
                        })
                    })
                });
                styleCache[size] = style;
            }
            return style;
        }
    });
    map.addLayer(vectorlayer);
}

export function addItemToMap(item) {
    clusterSource.addFeature(
        new Feature({
            geometry: new Point(fromLonLat([parseFloat(item.long), parseFloat(item.lat)])),
            id: item.id,
            name: 'Klo',
            item: item
        })
    );
}

function initGeolocation() {
    console.log('initGeolocation');
    geolocation = new Geolocation({
        // enableHighAccuracy must be set to true to have the heading value.
        trackingOptions: {
            enableHighAccuracy: true
        },
        projection: map.getView().getProjection()
    });

    locationIconFeature = new Feature({
        geometry: null,
        name: 'Location',
        population: 1,
        rainfall: 1
    });
    locationIconFeature.setStyle(locationIconStyle);

    geolocation.on('change:position', function() {
        var coordinates = geolocation.getPosition();
        locationIconFeature.setGeometry(coordinates ? new Point(coordinates) : null);
        if (goToToLocation) {
            map.getView().animate({ center: coordinates }, { zoom: 15 });
            goToToLocation = false;
        }
        if (selectClosestToilet) {
            selectClosestToilet = false;
            selectClosestToiletAt(coordinates);
        }
        console.log(coordinates);
    });

    // handle geolocation error.
    geolocation.on('error', function(error) {
        console.warn(error);
        let locationButton = document.getElementById('location-icon');
        locationButton.classList.remove('tracking');
        locationButton.innerHTML = 'location_disabled';
    });
    locationSource.addFeature(locationIconFeature);
}

/**Toggels location tracking. and zoom in on first location  */
export function trackLocation() {
    let locationButton = document.getElementById('location-icon');
    if (geolocation.getTracking()) {
        geolocation.setTracking(false);
        console.log('setTracking to false' + geolocation.getTracking());
        locationButton.classList.remove('tracking');
        goToToLocation = false;
    } else {
        geolocation.setTracking(true);
        console.log('setTracking to true' + geolocation.getTracking());
        locationButton.classList.add('tracking');
        goToToLocation = true;
    }
}

export function findKlo() {
    console.log('Find klo...');
    if (!geolocation.getTracking()) {
        trackLocation();
    }
    selectClosestToilet = true;
}

export function selectClosestToiletAt(coordinates) {
    var f = clusterSource.getClosestFeatureToCoordinate(coordinates);
    var feature = f.get('features');
    var size = feature.length;
    if (size === 1) {
        console.log(feature);
        onItemClicked(feature[0].values_.item);
        map.getView().animate(
            {
                center: fromLonLat([
                    parseFloat(feature[0].values_.item.long),
                    1 * parseFloat(feature[0].values_.item.lat) + 0.003
                ])
            },
            { zoom: 15 }
        );
    } else if (size > 1) {
        map.getView().animate(
            {
                center: fromLonLat([
                    parseFloat(feature[0].values_.item.long),
                    1 * parseFloat(feature[0].values_.item.lat) + 0.003
                ])
            },
            { zoom: 13 }
        );
    }
}
