import { toLonLat } from 'ol/proj';
var klowoMap = require('./map.js');

document.addEventListener('DOMContentLoaded', () => {
    console.log('windo heght: ' + window.innerHeight);
    document.getElementById('content').style.height = window.innerHeight + 'px';
    document.getElementById('map').style.height = window.innerHeight + 'px';
    klowoMap.initMap();
    listClicks();
    listEvents();
});

function listClicks() {
    let locationButton = document.getElementById('location-button');
    locationButton.onclick = function() {
        klowoMap.trackLocation();
    };
    document.querySelector('#new-button').addEventListener('click', function() {
        addNewItem();
    });
    document.querySelector('#cancleUploadItem').addEventListener('click', function() {
        cancleAddItem();
    });
    document.querySelector('#uploadItem').addEventListener('click', function() {
        uploadNewItem();
    });
    document.querySelector('#slectedfile').addEventListener('change', function() {
        selectFile();
    });
    document.querySelector('#uploadImage').addEventListener('click', function() {
        uploadImage();
    });
    document.querySelector('#cancleUploadImage').addEventListener('click', function() {
        cancleUploadImage();
    });
    document.querySelector('#add-rating-button').addEventListener('click', function() {
        if (!klowoMap.isBlocked()) addRatig();
    });
    document.querySelector('#cancleUploadRating').addEventListener('click', function() {
        cancleUploadRating();
    });
    document.querySelector('#uploadRating').addEventListener('click', function() {
        uploadRating();
    });
    document.querySelector('.star-selector').addEventListener('click', function(event) {
        ratingSelected(event);
    });
    document.querySelector('#uploadComment').addEventListener('click', function() {
        uploadComment();
    });
    document.querySelector('#add-comment-button').addEventListener('click', function() {
        if (!klowoMap.isBlocked()) writeComment();
    });
    document.querySelector('#cancleUploadComment').addEventListener('click', function() {
        cancleUploadComment();
    });
    document.querySelector('#find-button').addEventListener('click', function() {
        klowoMap.findKlo();
    });
}

function listEvents() {
    document.getElementById('itemName').addEventListener('change', function(event) {
        console.log(event);
        console.log('Input item name');
    });
}

function selectFile() {
    cancleUploadComment();
    cancleUploadRating();
    var myimg = document.getElementById('myimg');
    var input = document.getElementById('slectedfile');
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
            console.log('changed');
            myimg.src = e.target.result;
            document.getElementById('addSectionImage').classList.remove('removeToRight');
            klowoMap.blockBackdrop(true);
        };
        reader.readAsDataURL(input.files[0]);
    }
}

function cancleUploadImage() {
    var myimg = document.getElementById('myimg');
    myimg.src = '';
    document.getElementById('addSectionImage').classList.add('removeToRight');
    klowoMap.blockBackdrop(false);
}

function addRatig() {
    document.getElementById('addSectionRating').classList.remove('removeToRight');
    klowoMap.blockBackdrop(true);
}

function cancleUploadRating() {
    document.getElementById('addSectionRating').classList.add('removeToRight');
    var ratingstartElements = document.querySelector('.star-selector').children;
    if (ratingstartElements.length === 5) {
        for (let index = 0; index < ratingstartElements.length; index++) {
            ratingstartElements[index].innerHTML = 'star_border';
        }
    }
    klowoMap.blockBackdrop(false);
}

function ratingSelected(event) {
    //console.log(event);
    if (event.srcElement) {
        var star = event.srcElement;
        var name = star.innerHTML;
        while (star) {
            if (name === 'star') {
                star.innerHTML = 'star_border';
                star = star.nextElementSibling;
            } else if (name === 'star_border') {
                star.innerHTML = 'star';
                star = star.previousElementSibling;
            }
        }
    }
}

function uploadRating() {
    var itemId = klowoMap.selectedItem.item_id;
    var ratingstartElements = document.querySelector('.star-selector').children;
    var count = 0;
    if (ratingstartElements.length === 5) {
        for (let index = 0; index < ratingstartElements.length; index++) {
            const star = ratingstartElements[index];
            if (star.innerHTML === 'star') {
                count++;
            } else {
                break;
            }
        }
    }
    console.log('Rating is ' + count + '. Now uploading... ');
    var xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://klowo.org/klowo_items.php', true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onreadystatechange = function() {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            console.log('Rating uploaded, response:', this.responseText);
            cancleUploadRating();
            klowoMap.getRatings(itemId);
        }
    };
    xhr.send('add=rating&id=' + itemId + '&rating=' + count);
}

function writeComment() {
    document.getElementById('item-overlay').classList.add('removeToTop');
    document.getElementById('addSectionComment').classList.remove('removeToRight');
    klowoMap.blockBackdrop(true);
}

function cancleUploadComment() {
    document.getElementById('item-overlay').classList.remove('removeToTop');
    document.getElementById('addSectionComment').classList.add('removeToRight');
    document.getElementById('commentText').value = '';
    klowoMap.blockBackdrop(false);
}

function uploadComment() {
    var itemId = klowoMap.selectedItem.item_id;
    var commentText = document.getElementById('commentText').value;
    var hasMatch = commentText.match(/www\.|\.ru|\.com/gi);
    console.log(commentText + ' -> match: ' + hasMatch + ' = ' + !hasMatch);
    if (!hasMatch) {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://klowo.org/klowo_items.php', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onreadystatechange = function() {
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                console.log('Rating uploaded, response:', this.responseText);
                cancleUploadComment();
                klowoMap.getComments(itemId);
            }
        };
        xhr.send('add=comment&id=' + itemId + '&comment=' + commentText + '&autor=user');
    }
}

function uploadImage() {
    var itemId = klowoMap.selectedItem.item_id;
    console.log('Upload image for: ' + itemId);
    var slectedfile = document.getElementById('slectedfile').files[0];
    var imageFileSize = slectedfile.size / 800;
    console.log('imageFilesize: ' + imageFileSize);
    if (imageFileSize <= 6000) {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://klowo.org/klowo_items.php', true);

        //Send the proper header information along with the request
        // xhr.setRequestHeader('Content-Type', 'application/form-data');
        xhr.onreadystatechange = function() {
            // Call a function when the state changes.
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                console.log(this.responseText);
                cancleUploadImage();
                klowoMap.getImages(itemId);
            }
            klowoMap.backdroDisabled = false;
        };

        var formData = new FormData();
        formData.append('add', 'image');
        formData.append('id', itemId);
        formData.append('image', slectedfile);
        xhr.send(formData);
    }
}

function addNewItem() {
    //window.location.href = 'https://www.klowo.org/add.html';
    document.getElementById('addSectionItem').classList.remove('removeToRight');
    document.getElementById('button-wrapper').classList.add('removeToLeft');
    document.getElementById('map-center').classList.remove('hide');
    klowoMap.blockBackdrop(true);
}

function cancleAddItem() {
    document.getElementById('addSectionItem').classList.add('removeToRight');
    document.getElementById('button-wrapper').classList.remove('removeToLeft');
    document.getElementById('map-center').classList.add('hide');
    document.getElementById('zoom-in-text').innerHTML = '';
    klowoMap.blockBackdrop(false);
}

function uploadNewItem() {
    var name = document.getElementById('itemName').value;
    var view = klowoMap.map.getView();
    var zoom = view.getZoom();
    console.log(zoom);
    if (zoom > 15) {
        document.getElementById('zoom-in-text').innerHTML = '';
        var center = view.getCenter();
        center = toLonLat(center);
        var xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://klowo.org/klowo_add.php', true);

        //Send the proper header information along with the request
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

        xhr.onreadystatechange = function() {
            // Call a function when the state changes.
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                // Request finished. Do processing here.
                cancleAddItem();
                klowoMap.httpToilets(center);
            }
        };
        xhr.send('name=' + name + '&long=' + center[0] + '&lat=' + center[1]);
    } else {
        document.getElementById('zoom-in-text').innerHTML = 'Bitte weiter rein zoomen!';
    }
}
